import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { pageBoxProps } from "../../components/PageBoxProps";
import { headStyle, iconStyle, paraStyle } from "../../components/TextStyle";
import { motion as m } from "framer-motion";
import { useState } from "react";
import { stock } from "../../Constants";
import GitHubIcon from "@mui/icons-material/GitHub";


function StockPrediction() {
  const AnimatedGitHubIcon = m(GitHubIcon);
  const [isMobile] = useState(window.innerWidth < 600);

  return (
    <Box {...pageBoxProps}>
      <Typography
        aria-label="stock price prediction heading"
        sx={{ ...headStyle, textAlign: "center" }}
      >
        Stock Price Prediction Implementation and Improvement
      </Typography>
      <Typography
        sx={{ ...headStyle, textAlign: "center", fontSize: ["1.4rem", "2rem"] }}
      >
        Overview
      </Typography>
      <Typography sx={{ ...paraStyle }}>
        The goal of this solo project was to implement and improve the results
        of a recent paper by Jujie Wang and Jing Liu titled "Two-Stage Deep
        Ensemble Paradigm Based on Optimal Multi-scale Decomposition and
        Multi-factor Analysis for Stock Price Prediction"
        <sup>
          <a
            color="black"
            href="https://doi.org/10.1007/s12559-023-10203-x"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black" }}
          >
            1
          </a>
        </sup>{" "}
        The paper proposed a stock price prediction algorithm which consists of
        significant data preprocessing followed by a Bidirectional Gated
        Recurrent Unit (BiGRU) prediction model. By improving the frequency
        BiGRU models, the frequency predictions were sufficiently accurate to
        remove the final BiGRU integration model, outperforming the original
        paper and all 7 other models compared in the paper in all three error
        metrics. The data preprocessing consists of two distinct stages,
        followed by the prediction stage. For a detailed technical description
        of these steps, please see the{" "}
        <b>
          <a
            href="https://github.com/cbobowic/bigru_model_implementation"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black" }}
          >
            GitHub repository
          </a>
        </b>
        .<br></br>
        <br></br>
      </Typography>

      <figure
        aria-label="SSEC stock price prediction graph"
        style={{
          display: "flex",
          flexDirection: "column",
            minWidth: isMobile ? "80%" : "60%",
            alignItems: "center",
        }}
      >
        <img
          src="\images\SSEC_prediction.png"
          alt="SSEC Prediction"
          style={{ border: "1px solid black", maxWidth: isMobile ? "100%" : "60%" }}
        />
        <figcaption
          style={{
            fontStyle: "italic",
            textAlign: "center",
            fontSize: "0.8rem",
          }}
        >
          Figure: Replicated SSEC prediction results from original two-step BiGRU method by Wang and Liu.
        </figcaption>
      </figure>

      <Typography
        sx={{ ...headStyle, textAlign: "center", fontSize: ["1.4rem", "2rem"] }}
      >
        Novel Contributions
      </Typography>
      <Typography sx={{ ...paraStyle }}>
        The original paper proposed a two-step BiGRU integration model for the
        prediction stage. I found that the model could be improved in two key
        areas. The original paper did not include a dropout layer
        in the BiGRU model. I found that adding a dropout layer between the
        BiGRU and dense prediction layer significantly improved the performance
        of the low-frequency prediction model. The dropout layer reduced
        overfitting and allowed the model to better capture the long patterns in
        the data.<br></br><br></br>
        The improved performance from
        dropout layers at the frequency level resulted in highly accurate
        frequency predictions. Since the original signal can be reconstructed by
        summing the low-frequency and high-frequency series, I found that the
        final prediction model could be simplified by removing the final BiGRU
        layer and instead directly summing the low-frequency and high-frequency
        predictions. This reduced the complexity of the model and improved the
        Root Mean Square Error (RMSE), Mean Absolute Error (MAE), and Mean
        Absolute Percentage Error (MAPE) for the SZI index, with all three
        metrics outperforming the results reported in the original paper.
        <br></br>
        <br></br>
      </Typography>

      <Typography
        sx={{ ...headStyle, textAlign: "center", fontSize: ["1.4rem", "2rem"] }}
      >
        Results
      </Typography>
      <Typography sx={{ ...paraStyle }}>
        In both benchmark stocks, the improved BiGRU model outperformed the original paper and all 7 other
        models compared in the paper in all three error metrics. For technical details, benchmark results,
        and citations, please see the GitHub repository.
      </Typography>

      <figure
        aria-label="SSEC novel stock price prediction graph"
        style={{
          display: "flex",
          flexDirection: "column",
            minWidth: isMobile ? "80%" : "60%",
            alignItems: "center",
        }}
      >
        <img
          src="\images\SSEC_prediction_novel.png"
          alt="SSEC Prediction"
          style={{ border: "1px solid black", maxWidth: isMobile ? "100%" : "60%" }}
        />
        <figcaption
          style={{
            fontStyle: "italic",
            textAlign: "center",
            fontSize: "0.8rem",
          }}
        >
          Figure: Novel SSEC prediction results from improved BiGRU method.
        </figcaption>
      </figure>

      <a href={stock} target="_blank" rel="noreferrer">
        <AnimatedGitHubIcon
          aria-label="github icon"
          whileHover={{ scale: 1.2 }}
          sx={{ ...iconStyle, fontSize: "3rem", margin: "2rem" }}
        />
      </a>
    </Box>
  );
}

export default StockPrediction;
