import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { pageBoxProps } from "../../components/PageBoxProps";
import { headStyle, iconStyle, paraStyle } from "../../components/TextStyle";
import DownloadButton from "../../components/DownloadButton";
import { motion as m } from "framer-motion";
import GitHubIcon from "@mui/icons-material/GitHub";
import { stylegan, thispersondoesnotexist } from "../../Constants";
import UploadImageToModel from "../../components/file_upload/UploadImageToModel";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";

interface FileInputState {
  error: Error | null;
  showAlert: boolean;
}

function FaceDetection() {
  const [state, setState] = useState<FileInputState>({
    error: null,
    showAlert: false,
  });

  const AnimatedGitHubIcon = m(GitHubIcon);
  const [isMobile] = useState(window.innerWidth < 600);

  function handleError(error: Error) {
    setState({
      error,
      showAlert: true,
    });
  }

  return (
    <Box {...pageBoxProps}>
      {state.showAlert && (
        <Alert
          aria-label="error alert"
          severity="warning"
          color="error"
          sx={{ position: "fixed", marginTop: "1rem" }}
          onClose={() => setState({ ...state, error: null, showAlert: false })}
        >
          {state.error && state.error.message}
        </Alert>
      )}
      <Typography
        aria-label="stylegan heading"
        sx={{ ...headStyle, textAlign: "center" }}
      >
        StyleGAN Synthetic Face Detection
      </Typography>
      <Typography
        sx={{ ...headStyle, textAlign: "center", fontSize: ["1.4rem", "2rem"] }}
      >
        Overview
      </Typography>
      <Typography sx={{ ...paraStyle }}>
        As a part of my term project for CSCI 1430: Computer Vision, my group
        built a face detection model that can detect synthetic faces generated
        by StyleGAN with around 93% accuracy. This project was a great
        introduction to TensorFlow and computer vision, and I was able to apply
        my knowledge of deep learning to a real-world problem. The neural
        network uses a pretrained VGG16 face detector to extract features from
        the input image. We then fine-tuned a fully-connected classifier to
        produce the binary classification output. The model was trained on a
        dataset of 100,000 images and tested on a dataset of 20,000 images.
      </Typography>
      <Typography
        sx={{ ...headStyle, textAlign: "center", fontSize: ["1.4rem", "2rem"] }}
      >
        Try it out!
      </Typography>
      <Typography sx={{ ...paraStyle, marginBottom: "1.5rem" }}>
        Upload an image of a face to see if it is real or fake! The model seems
        to work relatively well on{" "}
        <Link target="_blank" href={thispersondoesnotexist} color={"#000000"}>
          this-person-does-not-exist.com
        </Link>{" "}
        images, since they are generated by StyleGAN. You can also try it out on
        an image of your own face! Make sure it is centered, well-lit, and takes
        up approximately 90% of the image! Note that since TensorFlow is running
        on the client-side, it may take a few seconds to load, especially on
        mobile devices. <strong>Uploaded images are not stored or sent to any server!</strong>
      </Typography>
      <UploadImageToModel onError={handleError} />
      <figure
        aria-label="vgg16"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minWidth: isMobile ? "100%" : "60%",
        }}
      >
        <img
          src="\images\vgg16_xml.png"
          style={{
            maxWidth: "80%",
            margin: "0rem 2rem 0rem 2rem",
            border: "1px solid black",
          }}
          alt="The CNN architecture based off of the vgg16 model."
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: "0.8rem",
            fontStyle: "italic",
            maxWidth: isMobile ? "80%" : "60%",

          }}
        >
          Figure: The CNN architecture based off of the vgg16 model.
        </figcaption>
      </figure>
      <Typography
        sx={{ ...headStyle, textAlign: "center", fontSize: ["1.4rem", "2rem"] }}
      >
        Results
      </Typography>
      <Typography sx={{ ...paraStyle }}>
        Surpassing the benchmark goal of 90% validation accuracy, we deemed our
        model a success. Additionally, after running LIME visualization and
        saliency maps, we were able to conclude that the model was focusing
        primarily on central facial features such as the bridge of the nose, the
        eyes, and the mouth. After further refinements, this model could be used
        to detect synthetic faces on social media platforms on a large scale and help prevent
        mass dissemination of misinformation.
      </Typography>
      <figure
        aria-label="saliency maps"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: isMobile ? "100%" : "60%",
          flexDirection: "column",
        }}
      >
        <img
          src="\images\saliency_maps.png"
          style={{
            maxWidth: isMobile ? "80%" : "60%",
            margin: "1rem 2rem 0rem 2rem",
            border: "1px solid black",
          }}
          alt="Sample saliency map results."
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: "0.8rem",
          maxWidth: isMobile ? "100%" : "60%",
          fontStyle: "italic",
          }}
        >
          Figure: Sample saliency map results.
        </figcaption>
      </figure>
      <figure
        aria-label="lime"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
            minWidth: isMobile ? "80%" : "60%",
            flexDirection: "column",
        }}
      >
        <img
          src="\images\lime_visualizations.png"
          style={{
            maxWidth: isMobile ? "100%": "60%",
            margin: "0rem 2rem 0rem 2rem",
            border: "1px solid black",
          }}
          alt="Sample LIME visualization results."
        ></img>
        <figcaption
          style={{
            textAlign: "center",
            fontSize: "0.8rem",
            fontStyle: "italic",
          }}
        >
          Figure: Sample LIME visualization results.
        </figcaption>
      </figure>

      <Typography sx={{ ...paraStyle, marginBottom: "1rem" }}>
        Access the poster presentation for this project:
      </Typography>
      <DownloadButton />
      <Typography sx={{ ...paraStyle, marginTop: "1rem" }}>
        Or check out the code on GitHub:
      </Typography>
      <a href={stylegan} target="_blank" rel="noreferrer">
        <AnimatedGitHubIcon
          aria-label="github icon"
          whileHover={{ scale: 1.2 }}
          sx={{ ...iconStyle, fontSize: "3rem", margin: "2rem" }}
        />
      </a>
    </Box>
  );
}

export default FaceDetection;
